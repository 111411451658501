@import '../../../../../../../../../assets/styles/variables';

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	margin: 16px 24px;
}


.counter {
	font-size: 48px;
	line-height: 56px;
	font-weight: 500;
	color: $color-grey2;
	margin-left: auto;

	&__current {
		font-size: 64px;
		line-height: 75px;
		color: $color-black;

		span {
			font-size: 48px;
			line-height: 56px;
		}
	}
}

.receiver {
	font-weight: 500;
}
