.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  span {
    text-align: center;
  }
}

@keyframes dotAnimation {
  0% {
     content: '';
   }
  33% {
      content: '.';
    }
  66% {
      content: '..';
    }
  100% {
       content: '...';
     }
}
.wrapperDots {
  display: flex;
}

.animatedDots::after {
  display: flex;
  width: 20px;
  animation: dotAnimation 1.5s steps(3, end) infinite;
  content: '';
}