@import '../../../../../../../../../assets/styles/variables';

.table {
	thead {
		& * {
			background-color: #fff !important;

			&::before {
				display: none;
			}
		}
		& > *:first-child > * {
			padding-left: 24px;
		}
	}
}

.row {
	background-color: $color-grey4;

	td {
		background-color: inherit !important;
	}

	&__check {
		background-color: #93FF5E;
	}
}

.cell {
	padding: 7px 0 !important;
	line-height: 22px;
	border: none !important;

	&:first-child > * {
		padding-left: 24px;
	}
}
