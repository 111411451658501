@import "../../../../../../../assets/styles/variables";

.modal {
  [class='ant-modal-header'] {
    margin-bottom: 0;
    padding-bottom: 16px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.footer {
  padding: 5px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
}

.table {
  margin-top: 24px;
}

.modal_confirm {
  padding: 32px;
  [class="ant-modal-header"] {
    display: none;
  }

  [class*='ant-modal-footer'] {
    display: none;
  }

  [class="ant-modal-close-x"] {
    display: none;
  }
}

.text {
  font-weight: 500;
  font-size: 16px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 24px;
}